import { setLocale } from "react-redux-i18n";
import { apiCall } from "./utils";
import {
  MOUNT_APP,
  REQUEST_LOGIN,
  REQUEST_PASS_TOKEN_BY_SMS_TOKEN,
  REQUEST_SMS_TOKEN,
  REQUEST_USERNAMES,
  REQUEST_USER_INFO,
  SET_OTP_SECRET,
  DELETE_OTP_SECRET,
  REQUEST_OTP_STATUS,
  SAVE_ID_NUMBER,
  SAVE_USERNAME,
  UPDATE_PASSWORD,
  BACKEND_LOGOUT,
  LOGOUT,
  SAVE_SESSION_TYPE,
  SAVE_USER_INFO,
  CLEAR_LOGOUT_MESSAGE,
  UPDATE_SITE_CONTAINER_LANGUAGE,
  OIDC_REQUEST_USERNAMES,
  SET_LOGOUT_MESSAGE,
  REQUEST_MFA_STATUS,
  REQUEST_ALL_MFA_STATUSES,
  DELETE_MFA_METHODS,
  GET_HIBP_STATUS,
} from "../actionConstants";
import pofhConfig from "../pofhConfig";

const {
  logoutEndpoint,
  reqPassTokenByLoginEndpoint,
  reqPassTokenBySMSTokenEndpoint,
  reqSMSTokenEndpoint,
  reqUsernamesEndpoint,
  updatePasswordEndpoint,
  oidc,
  userInfoEndpoint,
  otp,
  azure_mfa,
  hibp,
} = pofhConfig.apiSettings;

export const mountApp = () => (dispatch) => dispatch({ type: MOUNT_APP });

export function setLanguage(locale) {
  if (!["en", "nb"].includes(locale)) {
    return null;
  }
  return (dispatch) => {
    dispatch({ type: UPDATE_SITE_CONTAINER_LANGUAGE, locale: locale });
    dispatch(setLocale(locale));
  };
}

export function storeUsername(username) {
  return {
    type: SAVE_USERNAME,
    username,
  };
}

export function storeSessionType(sessionType) {
  return {
    type: SAVE_SESSION_TYPE,
    sessionType,
  };
}

export function storeUserInfo(userId, idmSource) {
  return {
    type: SAVE_USER_INFO,
    userId,
    idmSource,
  };
}

export function initiateLogin(payload) {
  return apiCall({
    method: "POST",
    endpoint: reqPassTokenByLoginEndpoint,
    type: REQUEST_LOGIN,
    payload,
  });
}

export function fetchPassTokenBySMSToken(payload) {
  return apiCall({
    method: "POST",
    endpoint: reqPassTokenBySMSTokenEndpoint,
    type: REQUEST_PASS_TOKEN_BY_SMS_TOKEN,
    authenticated: true,
    payload,
  });
}

export function fetchSMSToken(payload) {
  return apiCall({
    method: "POST",
    endpoint: reqSMSTokenEndpoint,
    payload,
    type: REQUEST_SMS_TOKEN,
  });
}

export function fetchUserNames(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_ID_NUMBER, identifier: payload.identifier });
    return dispatch(
      apiCall({
        method: "POST",
        endpoint: reqUsernamesEndpoint,
        payload,
        type: REQUEST_USERNAMES,
      })
    );
  };
}

export const fetchUserInfo = () => {
  return apiCall({
    method: "GET",
    endpoint: userInfoEndpoint,
    type: REQUEST_USER_INFO,
    authenticated: true,
  });
};

export const backendLogout = () => {
  return apiCall({
    method: "GET",
    endpoint: logoutEndpoint,
    type: BACKEND_LOGOUT,
  });
};

// When logged in via oidc, a cookie containing a JWT
// is sent automatically with each request, so no
// additional payload is required.
export function fetchOidcUserNames() {
  return apiCall({
    method: "GET",
    endpoint: oidc.reqUsernamesEndpoint,
    type: OIDC_REQUEST_USERNAMES,
    authenticated: true,
  });
}

export function updatePassword(payload) {
  return apiCall({
    method: "POST",
    endpoint: updatePasswordEndpoint,
    type: UPDATE_PASSWORD,
    authenticated: true,
    payload,
  });
}

export const logout = () => ({
  type: LOGOUT,
});

export const setLogoutMessage = (logoutHeader, logoutMessageTag, tagVars) => {
  const logoutMessageTagVars = {};

  if (tagVars) {
    Object.assign(logoutMessageTagVars, tagVars);
  }
  return {
    type: SET_LOGOUT_MESSAGE,
    logoutHeader,
    logoutMessageTag,
    logoutMessageTagVars,
  };
};

export const clearLogoutMessage = () => ({
  type: CLEAR_LOGOUT_MESSAGE,
});

export function setOtpSecret(payload) {
  return apiCall({
    method: "PUT",
    endpoint: otp.setSecretEndpoint,
    type: SET_OTP_SECRET,
    authenticated: true,
    payload
  });
}

export function getOtpStatus() {
  return apiCall({
    method: "GET",
    endpoint: otp.reqStatusEndpoint,
    type: REQUEST_OTP_STATUS,
    authenticated: true,
  });
}

export function delOtpSecret() {
  return apiCall({
    method: "DELETE",
    endpoint: otp.delSecretEndpoint,
    type: DELETE_OTP_SECRET,
    authenticated: true,
  });
}

export function getMfaStatus(username) {
  return apiCall({
    method: "GET",
    endpoint: azure_mfa.reqStatusEndpoint + encodeURIComponent(username),
    type: REQUEST_MFA_STATUS,
    authenticated: true,
  });
}

export function getAllMfaStatuses() {
  return apiCall({
    method: "GET",
    endpoint: azure_mfa.reqStatusAllEndpoint,
    type: REQUEST_ALL_MFA_STATUSES,
    authenticated: true,
  });
}

export function delMfaMethods(username) {
  return apiCall({
    method: "DELETE",
    endpoint: azure_mfa.delMethodsEndpoint + username,
    type: DELETE_MFA_METHODS,
    authenticated: true,
  });
}

export function postHibpPasswordStatus(payload) {
  return apiCall({
    method: "POST",
    endpoint: hibp.hibpEndpoint || "/hibp/",
    payload,
    type: GET_HIBP_STATUS,
    authenticated: true,
  });
}
