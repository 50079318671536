import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import Page from '../../components/page';
import LinkList from '../../components/linklist';
import ButtonLink from 'components/buttonlink';

import {
  forgottenPasswordPath,
  getSmsTokenPath,
  forgottenUsernamePath,
  newUserPath,
  getUsernamesPath,
  newUserSMSPath,
  loginPath,
  mfaInfoPath
} from '../../routeConstants';

import pofhConfig from '../../pofhConfig';
const {
  disableNewUserPage,
  disableGetUsernames,
  mfaResetEnabled,
} = pofhConfig.appSettings;

const { oidc } = pofhConfig.apiSettings;

class Frontpage extends React.Component {
  render() {
    return (
      <Page header={<Translate value="general.password_header" />}>
        <ButtonLink
          href={loginPath}
          text={I18n.t("general.change_password")}
          type={'primary'}
          isRoute
        />
        <br>
        </br>
        {oidc ? (
          <LinkList>
            <Link to={forgottenPasswordPath}>
              <Translate value="general.forgotten_password" />
            </Link>
            <Link to={forgottenUsernamePath}>
              <Translate value="general.forgotten_username" />
            </Link>
            {!disableNewUserPage && (
              <Link to={newUserPath}>
                <Translate value="general.new_user" />
              </Link>
            )}
            {mfaResetEnabled && (
              <Link to={mfaInfoPath}>
                <Translate value="general.mfa_page_header" />
              </Link>
            )}
          </LinkList>
        ) : (
          <LinkList>
            <Link to={getSmsTokenPath}>
              <Translate value="general.forgotten_password" />
            </Link>
            {!disableGetUsernames && (
              <Link to={getUsernamesPath}>
                <Translate value="general.forgotten_username" />
              </Link>
            )}
            {!disableNewUserPage && (
              <Link to={newUserSMSPath}>
                <Translate value="general.new_user" />
              </Link>
            )}
          </LinkList>
        )}
      </Page>
    );
  }
}

Frontpage.propTypes = {
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(Frontpage);
