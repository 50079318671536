import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import pofhConfig from '../../pofhConfig';
import Input from '../input'
import { DropDownRF } from "../dropdown";
import FieldGroup from '../fieldgroup';
import Label from '../label';
import Button, { ButtonGroup } from '../button';
import ReCaptcha from '../recaptcha';
import countryData from 'country-data';
import countryDataNo from '../../countryNamesNo.json';
import Warning from '../warning';
const {
  reCaptchaEnabled,
  SMSTokenRequiresId,
  lowerCaseUsernameOnly,
} = pofhConfig.appSettings;



const lower = value => value && value.toLowerCase();
const noop = value => value;
const usernameNormalizer = lowerCaseUsernameOnly ? lower : noop;

const validate = (fields) => {
  const error = {};
  if (!fields.username)
    error.username = <Translate value="formErrors.requiredField" />;

  if (!fields.countrycode)
    error.countrycode = error.mobile = <Translate value="formErrors.requiredField" />;

  if (fields.countrycode && !/^\+\d+$/.test(fields.countrycode))
    error.countrycode = <Translate value="formErrors.countryCode" />;

  if (!fields.mobile)
    error.mobile = <Translate value="formErrors.requiredField" />;

  if (fields.mobile && !/^\d+$/.test(fields.mobile))
    error.mobile = <Translate value="formErrors.mobilePhoneNr" />;

  if (!fields.identifier)
    error.identifier = <Translate value="formErrors.requiredField" />;

  if (fields.identifier && ![6, 11].includes(fields.identifier.length))
    error.identifier = <Translate value="formErrors.identifierLength" />;

  if (fields.identifier && !/^\d+$/.test(fields.identifier))
    error.identifier = <Translate value="formErrors.onlyNumbers" />;

  if (!fields['g-recaptcha-response'])
    error['g-recaptcha-response'] = <Translate value="formErrors.requiredField" />;

  return error;
};

const identifierLabel = () => (
  <span>
    <Translate value="general.student_nr" /> /
    <br />
    <Translate value="general.social_security_nr" />
  </span>
);

class GetSmsTokenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { prefixData: this.buildPrefixData(props.locale) }
  }

  buildPrefixData(locale) {
    const countryToPrefix = {};
    const validPrefixes = []
    let countryList = null;
    if (locale === 'en') {
      countryList = countryData.countries.all;
      countryList.forEach(country => {
        country.countryCallingCodes.forEach(prefix => {
          countryToPrefix[`${country.name} (${prefix})`] = prefix;
          validPrefixes.push(prefix);
        });
      })
    }
    else if (['nb', 'nn'].includes(locale)) {
      countryList = countryDataNo;
      Object.keys(countryList).forEach(alpha3Code => {
        const country = countryData.countries[alpha3Code];
        country.countryCallingCodes.forEach(prefix => {
          countryToPrefix[`${countryList[alpha3Code]} (${prefix})`] = prefix;
          validPrefixes.push(prefix);
        });
      })
    }
    else {
      console.error(`Unsupported locale for phone prefixes: ${locale}`)
    }
    return {
      prefixValues: countryToPrefix,
      countries: Object.keys(countryToPrefix).sort(),
      validPrefixes
    };
  }

  render() {
    const submitDisabled = this.props.pristine || !this.props.valid;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field name="username"
          label={I18n.t('general.username')}
          locale={this.props.locale}
          component={Input}
          autofocus={!this.props.initialValues.username}
          normalize={usernameNormalizer}
          required
        />
        {SMSTokenRequiresId &&
          <Field name="identifier"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            label={identifierLabel()}
            locale={this.props.locale}
            component={Input}
            required
          />
        }
        <div className="inline-form-group">
          <Label
            labelFor="mobile"
            text={I18n.t('general.mobile_phone_nr')}
          />
          <FieldGroup inline>
            <Field
              name="countrycode"
              component={DropDownRF}
              required
              label={I18n.t('general.country_code')}
              options={this.state.prefixData.countries}
              optionValues={this.state.prefixData.prefixValues}
            />
            <Field name="mobile"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              locale={this.props.locale}
              component={Input}
              autofocus={!!this.props.initialValues.username}
              required
              label={I18n.t('general.mobile_phone_nr')}
              smallLabel
            />
          </FieldGroup>
        </div>
        {reCaptchaEnabled &&
          <Field name="g-recaptcha-response"
            component={ReCaptcha}
          />
        }
        {this.props.error &&
          <Warning text={this.props.error} color="red" formWarning={true} />
        }
        <ButtonGroup>
          <Button onClick={this.props.cancel}
            type='button'
            text={I18n.t('general.cancel')}
            secondary
          />
          <Button type='submit'
            text={I18n.t('general.continue')}
            fetching={this.props.submitting}
            disabled={submitDisabled}
          />
        </ButtonGroup>
      </form>
    )
  }
}
GetSmsTokenForm = reduxForm({ form: 'getSmsTokenForm', validate })(
  GetSmsTokenForm
)

GetSmsTokenForm.propTypes = {
  locale: PropTypes.string
};

export default connect((state) => {
  const initialValues = { countrycode: '+47' }
  if (state.session.username) {
    initialValues.username = state.session.username;
  }
  if (SMSTokenRequiresId && state.session.identifier) {
    initialValues.identifier = state.session.identifier;
  }

  return {
    locale: state.i18n.locale,
    initialValues: initialValues,
  };
})(GetSmsTokenForm);
