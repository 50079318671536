import React from 'react';
import classNames from 'classnames';

export const EyeIcon = ({ onClick, visible, error }) => {
  const eyeIconClassNames = classNames({
    'eyeIcon': true,
    'eyeIconActive': visible,
    'eyeIconError': error
  });
  return (
    <svg className={eyeIconClassNames}
      onClick={onClick}
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 32 32">
      <g>
        <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16
                 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889
                 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79
                 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889
                 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697
                 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311
                  0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582
                  8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371
                  0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343
                  3 3z"></path>
      </g>
    </svg>
  )
};

export const InfoIcon = () => {
  return (
    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <title id="infoIcon">Info</title>
      <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52
               2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
    </svg>
  )
};

export const CloseIcon = () => {
  return (
    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title id="closeIcon">Close</title>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59
             19 19 17.59 13.41 12z"/>
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
};

export const CheckmarkOn = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52
               0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#0B7044" />
    </svg>
  )
};

export const CheckmarkOff = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.59 6L10 8.59L7.41 6L6 7.41L8.59 10L6 12.59L7.41 14L10 11.41L12.59 14L14 12.59L11.41
               10L14 7.41L12.59 6ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20
               10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59
               18 10C18 14.41 14.41 18 10 18Z" fill="#B60000" />
    </svg>
  )
};

export const PhoneCheckmarkOn = () => {
  return (
    <svg width="91" height="143" viewBox="0 0 91 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10C0 4.47715 4.47715 0 10 0H81C86.5229 0 91 4.47715 91 10V133C91 138.523 86.5229 143 81 143H10C4.47715 143 0 138.523 0 133V10Z" fill="#26457D" />
      <g clipPath="url(#clip0_84_6665)">
        <rect x="6" y="7" width="78" height="129" rx="10" fill="#2EC483" />
        <rect x="21" y="4" width="49" height="7" rx="3.5" fill="#26457D" />
        <rect x="25" y="73.6465" width="8" height="25" rx="4" transform="rotate(-44.8944 25 73.6465)" fill="white" />
        <rect x="61.6123" y="55" width="8" height="38.7075" rx="4" transform="rotate(37.5909 61.6123 55)" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_84_6665">
          <rect x="6" y="7" width="78" height="129" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}
